import "./globals.css";
import "./styleguide.css";
import "./overall.css";
import React, {
	lazy,
	createContext,
	Suspense,
	useReducer,
	useState,
	useEffect,
	useCallback,
} from "react";
import { Switch, Router, Route } from "react-router-dom";
import Loading from "./components/Loading/loading.component";
import NotFound from "./Pages/NotFound/not-found.component";
import { initialState, rootReducer } from "./state/root.reducer";
import { createBrowserHistory } from "history";
import GlobalHeaderMenu from "./components/GlobalHeaderMenu";
import SEO from "./components/SEO/seo.component";
// import { Helmet } from "react-helmet-async";
import Conditions from "./Pages/Conditions/conditions.page";
import Politiques from "./Pages/Politiques/politiques.page";

const Index = lazy(() => import("./Pages/Index"));
const Login = lazy(() => import("./Pages/Login/login.page"));
const Contactez = lazy(() => import("./Pages/contactez/contactez"));
const BeliBox = lazy(() => import("./Pages/BeliBox"));
const Lp = lazy(() => import("./Pages/Lp"));
const FAQ = lazy(() => import("./Pages/FAQ"));
const Footer = lazy(() => import("./components/Footer"));
// const JaiUneBelibox = lazy(() => import("./Pages/AgenceDeVoyages"));
const Sejour = lazy(() => import("./Pages/Sejour/sejour.component"));
const QuisommesNous = lazy(() =>
	import("./Pages/quisommesnous/quisommesnous.component")
);
const DevenirPartenaire = lazy(() =>
	import("./Pages/DevenirPartenaire/devenir-partenaire.component")
);
const UserPortal = lazy(() => import("./Pages/UserPortal/user-portal.page"));
const NewAgence = lazy(() => import("./Pages/NewAgence/new-agence.component"));

export const AppContext = createContext({});

export const history = createBrowserHistory();

const PagesWithFixedMenu = ["login", "userportal", "conditions", "politiques"];

function App() {
	const [state, dispatch] = useReducer(rootReducer, initialState);
	const [fixedMenu, setFixedMenu] = useState(false);
	const [y, setY] = useState(window.scrollY);

	const shouldPageMenuBeFixed = (path) => {
		const ix = PagesWithFixedMenu.findIndex((p) => path.indexOf(p) >= 0);
		return ix >= 0;
	};
	const handleNavigation = useCallback(
		(e) => {
			const window = e.currentTarget;
			const { pathname: path } = window.location;
			// console.log(window.location);
			if (shouldPageMenuBeFixed(path)) return;

			if (y > window.scrollY) {
				if (window.scrollY === 0) {
					setFixedMenu(false);
				}
				// console.log("up");
			} else if (y < window.scrollY) {
				// console.log("down");
				if (window.scrollY > 1 && !fixedMenu) setFixedMenu(true);
			}
			setY(window.scrollY);
		},
		// eslint-disable-next-line
		[y]
	);
	useEffect(() => {
		setY(window.scrollY);
		window.addEventListener("scroll", handleNavigation);

		return () => {
			window.removeEventListener("scroll", handleNavigation);
		};
	}, [handleNavigation]);

	useEffect(() => {
		const { pathname: path } = window.location;
		if (shouldPageMenuBeFixed(path)) {
			setFixedMenu(true);
			return;
		}
		setFixedMenu(false);
		// eslint-disable-next-line
	}, [window.location.pathname]);

	useEffect(() => {
		dispatch({
			type: "REFRESH_LOGIN",
		});
	}, []);

	const isLp = () =>{
		const { pathname: path } = window.location;
		return path.indexOf("lp") >= 0;
	}

	return (
		<AppContext.Provider value={[state, dispatch]}>
			<Router history={history}>
				<Suspense
					fallback={
						<div className="loading-screen-suspense">
							<Loading />
						</div>
					}
				>
					<SEO />
					{!isLp()  && <GlobalHeaderMenu fixed={fixedMenu} />}
					
					<Switch>
						<Route path="/(|index)">
							<Index />
						</Route>
						<Route path="/belibox">
							<BeliBox />
						</Route>
						<Route path="/lp">
							<Lp />
						</Route>
						<Route path="/faq">
							<FAQ />
						</Route>
						{/* <Route path="/j-ai-une-belibox">
							<JaiUneBelibox />
						</Route> */}
						<Route path="/sejour">
							<Sejour />
						</Route>
						<Route path="/quisommesnous">
							<QuisommesNous />
						</Route>
						<Route path="/contactez">
							<Contactez />
						</Route>
						<Route path="/devenirPartenaire">
							<DevenirPartenaire />
						</Route>
						<Route path="/agencedevoyages">
							<NewAgence />
						</Route>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/userportal">
							<UserPortal />
						</Route>
						<Route path="/conditions">
							<Conditions />
						</Route>
						<Route path="/politiques">
							<Politiques />
						</Route>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>

					{!isLp()  && <Footer />}
					

				</Suspense>
			</Router>
		</AppContext.Provider>
	);
}

export default App;
